import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'
import Image from 'next/image'
import FuelLogo from './fuel-logo-white.png'

const Container = styled(Box)`
  display: flex;
  justify-content: center;
`

const LeftButtonSide = styled(Box)`
  display: flex;
  align-items: center;
`
const RightButtonSide = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`
const Caption = styled(Box)`
  font-size: 14px;
`
const Subtext = styled(Box)`
  font-size: 12px;
  font-weight: 400;
  color: rgba(140, 140, 140, 1);
`

export const MadeByFuel = () => {
  const MadeByFuelContainer = styled(Box)`
    display: flex;
    justify-content: center;
    padding: 14px;
    gap: 10px;
    border-radius: 8px;
    background-color: transparent;
    backdrop-filter: blur(8px);
    color: white;
    border: 1px solid rgba(111, 111, 111, 1);
    font-family: Oswald-regular, sans-serif, serif;

    &:hover {
      cursor: pointer;
      background-color: #fafafa;
      color: rgba(31, 31, 31, 1);
    }
  `

  return (
    <Container>
      <MadeByFuelContainer onClick={() => window.open('https://onfuel.io', '_blank')}>
        <LeftButtonSide grow={1}>
          <Image alt="fuel-logo" src={FuelLogo} style={{ borderRadius: '4px' }} height="36px" width="36px" />
        </LeftButtonSide>
        <RightButtonSide grow={2}>
          <Caption>Made with FUEL</Caption>
          <Subtext>Get in touch</Subtext>
        </RightButtonSide>
      </MadeByFuelContainer>
    </Container>
  )
}
