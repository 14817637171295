import { IconName } from '@fortawesome/free-brands-svg-icons'
import { IconType } from 'react-icons/lib'

export enum PaymentID {
  STRIPE = 'stripe',
  COINBASE = 'coinbase',
  PAYPAL = 'paypal',
}

export type PaymentMethod = {
  id: string
  icon: IconType | string | IconName
  title: string
  subtitle: string
  recommended: boolean
  payment_id: PaymentID
}
