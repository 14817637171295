import useChallengeAuth from '@fuel/core/hooks/useChallengeAuth'
import { api } from '@fuel/core'

const useConnectedApps = () => {
  const axiosApi = useChallengeAuth()

  const getUserSpotify = async () => {
    const { data: status } = await axiosApi.get<Record<string, boolean>>(api.GET_USER_SPOTIFY)

    return status.connected
  }

  const deleteUserSpotify = async () => {
    return axiosApi.delete(api.DELETE_SPOTIFY_USER)
  }

  return {
    getUserSpotify,
    deleteUserSpotify,
  }
}

export default useConnectedApps
