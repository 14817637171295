import { Icon } from '@chakra-ui/react'

export const PayPalIcon = (props: any) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <svg width="200" height="207" viewBox="0 0 200 207" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M198.423 95.4026C190.721 130.365 161.927 150.938 127.483 150.938H91.4971L79.1617 206.954H28.7872L33.5447 185.792H50.3833H63.7732L66.6553 172.72L76.1087 129.777H98.7045C142.491 129.777 176.719 102.843 185.909 61.177C186.684 57.644 187.204 54.2884 187.521 51.0574C197.369 60.4945 202.911 75.101 198.423 95.4026ZM37.2871 169.125H20.209H0L38.0469 0H123.896C139.789 0 157.31 7.19065 165.88 22.4175C168.832 27.6712 170.729 33.8694 171.125 41.0773C171.403 46.0817 170.963 51.5837 169.637 57.5814C166.631 71.2428 160.369 82.6484 151.847 91.5444C138.82 105.151 120.448 112.753 99.9753 113.054C99.5496 113.062 99.1313 113.108 98.705 113.108H62.7114L50.3833 169.125H37.2871ZM69.9094 80.7119H93.5514C98.7972 80.7119 104.02 78.9833 108.608 76.1013C110.358 74.9778 112.041 73.7144 113.559 72.2805C117.805 68.2907 121.005 63.1839 122.347 57.5814C124.701 49.1741 121.973 41.8751 116.347 37.8375C113.388 35.7148 109.645 34.4588 105.384 34.4588H80.7114L79.9205 37.8375L69.9094 80.7119Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
