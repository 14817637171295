import { ReactElement } from 'react'
import CookieConsent from 'react-cookie-consent'
import { useTranslation } from 'next-i18next'
import { Box, Button, Container, Flex, Heading, HStack, Text } from '@chakra-ui/react'
import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons'
import { AuthMagicConnectButton } from '@fuel/auth/components/AuthMagicConnectButton/AuthMagicConnectButton'
import { Logo } from './Logo'
import { NavigationList } from './navigation/NavigationList'
import { NavigationItemModel } from '../model/NavigationItemModel'
import { LanguageSwitcher } from './LanguageSwitcher'

interface DashboardLayoutProps {
  title?: string
  backAction?: () => void
  exitAction?: () => void
  logoRoute: string
  step?: number
  align?: 'left' | 'center'
  navigationItems?: NavigationItemModel[]
  actions?: any
  children: any
  notifications?: ReactElement
  redirectPathOnLogout?: string
  hideWallet?: boolean
}

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  title,
  backAction,
  align = 'center',
  exitAction,
  navigationItems = [],
  actions,
  logoRoute,
  step,
  redirectPathOnLogout = '/',
  notifications = null,
  hideWallet,
}) => {
  const { t } = useTranslation('common')

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mt="10px" mx={['15px', '48px']} flexDirection={['column', 'row']}>
        <Logo route={logoRoute} />
        <Flex gap="20px">
          <NavigationList items={navigationItems} />
          <LanguageSwitcher />
          <AuthMagicConnectButton
            avatarButton={true}
            hideWallet={hideWallet}
            redirectPathOnLogout={redirectPathOnLogout}
            profileText={t('my_wallet')}
            logoutText={t('logout')}
            connectText={t('connect')}
            hideConnctedAppsItem={true}
          />
        </Flex>
      </Flex>
      <Container maxW="container.xl" px={['15px', '48px']} m={align === 'left' ? 0 : 'auto'}>
        <>
          {notifications}
          <Box mt={['40px', '50px']}>
            <>
              {(backAction || exitAction) && (
                <HStack w="full" justifyContent="space-between" alignItems="center" mb="50px">
                  {backAction && (
                    <Button onClick={backAction} variant="link" leftIcon={<ArrowBackIcon mb="3px" />}>
                      Back
                    </Button>
                  )}
                  {exitAction && (
                    <Button onClick={exitAction} variant="link" leftIcon={<CloseIcon mb="3px" boxSize="10px" />}>
                      Exit
                    </Button>
                  )}
                </HStack>
              )}
              {step && (
                <Text color="grey.300" fontWeight={600} fontSize="16px" mb="10px">
                  Step {step}
                </Text>
              )}

              {title && (
                <HStack w="full" justifyContent="space-between">
                  <Heading as="h2" size="xl" alignSelf="flex-start">
                    {title}
                  </Heading>

                  {actions ? actions : null}
                </HStack>
              )}
            </>

            <Box mt="30px" mb="60px" bg="#FAFAFA">
              {children}
            </Box>

            <CookieConsent
              style={{
                background: 'rgb(12, 43, 50)',
              }}
              buttonStyle={{
                background: '#D5FE9C',
                color: '#000',
                fontSize: '13px',
                borderRadius: '12px',
                fontFamily: 'Space Mono',
                fontWeight: 'bold',
              }}
            >
              {t('cookies_consent')}{' '}
              <a style={{ color: '#fff' }} href="https://docs.google.com/document/d/1dQAQUwwzDsUgJ43w01LOWDS2bqakaEAtgvuvykiK0yE/edit?usp=sharing">
                {t('privacy_policy')}
              </a>
            </CookieConsent>
          </Box>
        </>
      </Container>
    </>
  )
}
