import { mode } from '@chakra-ui/theme-tools'

export const globalStyles = {
  global: (props: any) => ({
    body: {
      background: `${props.theme.colors.background.primary}`,
      overflowX: 'hidden',
      lineHeight: 1.5,
      fontSize: '16px',
    },
  }),
}
