import { FC } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Box, Button, Menu, MenuButton, MenuList, MenuItem, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { NavigationItemModel } from '@fuel/core/model/NavigationItemModel'
import styled from '@emotion/styled'

const StyledMenuButton = styled(MenuButton)`
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`

interface NavigationItemProps {
  navigationItem: NavigationItemModel
}

export const NavigationItem: FC<NavigationItemProps> = ({ navigationItem }) => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { name, url, translation_key } = navigationItem

  if (!navigationItem?.subroutes) {
    return (
      <Link href={url} passHref={true}>
        <Button variant="link" color={router.pathname === url ? 'secondary.accent' : 'grey.300'}>
          {translation_key ? t(translation_key) : <span>{name}</span>}
        </Button>
      </Link>
    )
  }

  const { subroutes } = navigationItem

  return (
    <Box alignSelf="center">
      <Menu gutter={2} placement="bottom" isOpen={isOpen}>
        <StyledMenuButton onMouseEnter={onOpen} onMouseLeave={onClose}>
          <Button variant="link" color={subroutes.some(route => route.url === router.asPath) ? 'secondary.accent' : 'grey.300'}>
            {translation_key ? t(translation_key) : <span>{name}</span>}
          </Button>
        </StyledMenuButton>
        <MenuList sx={{ minWidth: '140px', borderRadius: '1rem', padding: '5px 10px' }} onMouseEnter={onOpen} onMouseLeave={onClose}>
          {subroutes.map(route => (
            <MenuItem
              key={route.name}
              sx={{ borderRadius: '0.5rem' }}
              backgroundColor="transparent"
              color="secondary.dark"
              _focus={{ backgroundColor: '#F6F5FF', color: 'secondary.accent' }}
              _hover={{ backgroundColor: '#F6F5FF', color: 'secondary.accent' }}
            >
              <Link href={route.url}>{route.name}</Link>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}
