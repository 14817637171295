import '@rainbow-me/rainbowkit/styles.css'
import '../styles/fonts.css'
import Head from 'next/head'
import { ThemeProvider } from 'styled-components'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeft,
  faArrowRightFromBracket,
  faBox,
  faChartLine,
  faCheck,
  faCrown,
  faEdit,
  faFolder,
  faHeartCrack,
  faPlus,
  faTrash,
  faUpload,
  faUser,
  faWallet,
  faX,
} from '@fortawesome/free-solid-svg-icons'
import ReactModal from 'react-modal'
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useState } from 'react'
import { appWithTranslation, useTranslation } from 'next-i18next'
import nextI18nConfig from '../next-i18next.config'
import { creatorsTheme } from '@fuel/theme'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { MagicConnectWrapperProvider } from '@fuel/auth'
import { ChakraProvider } from '@chakra-ui/react'
import { theme as chakraTheme } from '../theme/theme'
import { useLanguageDetector } from '@fuel/core'
import languageDetector from '../utilities/languageDetector'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_URL,
    tracesSampleRate: 1.0,
  })
}

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
  debug: process.env.NODE_ENV === 'development',
})

library.add(
  faArrowRightFromBracket,
  faFolder,
  faWallet,
  faUser,
  faPlus,
  faArrowLeft,
  faCheck,
  faX,
  faBox,
  faCrown,
  faChartLine,
  faUpload,
  faTrash,
  faEdit,
  faHeartCrack
)
ReactModal.setAppElement('#__next')

const App = (props: any) => {
  useLanguageDetector(languageDetector)
  const { t } = useTranslation('common')
  const { Component, pageProps } = props
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      })
  )

  return (
    <ChakraProvider theme={chakraTheme}>
      <ThemeProvider theme={creatorsTheme as any}>
        <QueryClientProvider client={queryClient}>
          <MagicConnectWrapperProvider>
            <Hydrate state={pageProps.dehydratedState}>
              <ReactQueryDevtools initialIsOpen={false} />

              <Head>
                <title>Fuel | {t('wallet')}</title>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
              </Head>

              <Component {...pageProps} />
            </Hydrate>
          </MagicConnectWrapperProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ChakraProvider>
  )
}

export default appWithTranslation(App, nextI18nConfig)
