import { useLogout } from '@fuel/auth'
import axios from 'axios'
import { errors } from '../utils/errorCodes'
import { refreshToken } from '../utils/refreshToken'
import { getLocalStorage } from '@fuel/core/services/localStorage'
import { storage } from '@fuel/core'
import { useContext } from 'react'
import { ModalsContext } from '@fuel/core/context/ModalsProvider'

const useAxiosWithAuth = (authTokenName = 'Token', backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL) => {
  const { logout } = useLogout()
  const { onOpenErrorModal } = useContext<any>(ModalsContext)

  const baseURL = backendUrl

  const axiosInstance = axios.create({
    baseURL,
  })

  axiosInstance.interceptors.request.use(
    async config => {
      const authTokens = getLocalStorage(storage.AUTH_TOKENS)
      if (config.headers && authTokens) {
        config.headers['Authorization'] = `${authTokenName} ` + authTokens?.accessToken
      }

      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const config = error.config

      if (error?.config?.params?.removeInterceptors) {
        return Promise.reject(error)
      }

      if (error?.response?.status === 500) {
        onOpenErrorModal()
      }

      if (error?.response?.status === 401) {
        if (error.response.data.code === errors.INVALID_TOKEN.code) {
          logout()
          return Promise.reject(error)
        }

        if (error?.response?.data.code === errors.WRONG_SIGNATURE.code) {
          logout()
          return Promise.reject(error)
        }

        if (error?.response?.data.code === errors.JWT_EXPIRED.code) {
          return refreshToken(config, logout)
        }
      }

      return Promise.reject(error)
    }
  )

  return axiosInstance
}

export default useAxiosWithAuth
