import { extendTheme, ThemeConfig } from '@chakra-ui/react'

import { globalStyles as styles } from './styles'
import { colorValues as colors } from './base/colors'
import { breakpoints } from './base/breakpoints'

import { Button } from './components'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

export const theme = extendTheme({
  config,
  styles,
  colors,
  breakpoints,
  spacing: (multiplier = 1) => `${4 * multiplier}px`,
  components: {
    Button,
  },
})
