import { FC } from 'react'
import { NavigationItem } from './NavigationItem'
import { NavigationItemModel } from '../../model/NavigationItemModel'
import { Flex } from '@chakra-ui/react'

interface NavigationListProps {
  items: NavigationItemModel[]
}

export const NavigationList: FC<NavigationListProps> = ({ items = [] }) => {
  return (
    <Flex gap="64px" mr={['16px', '64px']}>
      {items.map(navigationItem => (
        <NavigationItem key={navigationItem.name} navigationItem={navigationItem} />
      ))}
    </Flex>
  )
}
