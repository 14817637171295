export const colorValues = {
  primary: {
    main: '#D5FE9C',
    dark: '#57A871',
  },
  secondary: {
    main: '#0C2B32',
    dark: '#172F38',
  },
  alt: {
    main: '#907DFE',
  },
  button: {
    primary: '#000',
    secondary: '#fff',
  },
  text: {
    primary: '#57A871',
    secondary: '#fff',
    disabled: '#A0ABB0',
    warning: '#D8980B',
    success: '#57A871',
    error: '#F44336',
    dark: '#000',
    light: '#fff',
  },
  background: {
    primary: '#fafafa',
    light: '#FBFBFB',
    stroke: '#DBE3EC',
  },
  common: {
    white: '#FFF',
    gray: '#A0ABB0',
    disabled: '#A0ABB060',
    error: '#F44336',
    success: '#D6FE9B',
    info: '#33A2B8',
    warning: '#FBEDCF',
  },
}
