import { Box, Checkbox, Link, Text } from '@chakra-ui/react'
import { DropPageDataContext } from '@fuel/auth/context/DropPageDataProvider'
import { storage } from '@fuel/core'
import { getLocalStorage } from '@fuel/core/services/localStorage'
import { usePayPalScriptReducer } from '@paypal/react-paypal-js'
import { CollectionContext } from '@fuel/nft'
import React, { useContext, useState } from 'react'
import { paymentMethods } from '../../../constants/paymentMethods'
import { PaymentID } from '../../../model/PaymentMethod'
import { PayPalButton } from '../../PayPalButton/PayPalButton'
import { usePayPal } from '../../../hooks/usePayPal'
import { StyledHeaderText, StyledPayButton, StyledTotalText } from './Payment.styled'
import PaymentMethodsList from './PaymentMethodsList/PaymentMethodsList'
import { StyledHStack } from './PaymentMethodsList/PaymentMethodsList.styles'
import { useTranslation } from 'next-i18next'
import styled from '@emotion/styled'

const PaymentButtonContainer = styled(Box)`
  height: 40px;
  display: flex;
  justify-content: center;
`

const StyledLink = styled(Link)`
  text-decoration: underline;
`

const StyledBox = styled(Box)`
  margin: 16px 0 40px;
`

const StyledCheckbox = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #a0abb0;
  text-align: left;
  margin-left: 10px;
`

export const StyledSubtitle = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #cfd4eb;
  margin-bottom: 30px;
`

type PaymentModalProps = {
  onPaymentSelected: (selectedPaymentMethod: string) => void
  isTurkish?: boolean
  purchaseQuantity: number
  allowedPaymentIds?: PaymentID[]
}

export default function Payment({ onPaymentSelected, isTurkish = false, purchaseQuantity, allowedPaymentIds }: PaymentModalProps) {
  const { t } = useTranslation('stepper')
  const [isPaying, setIsPaying] = useState(false)
  const [value, setValue] = useState('1')
  const [termsChecked, setTermsChecked] = useState(false)
  const [isPrivacyChecked, setPrivacyChecked] = useState(false)
  const { collection } = useContext<any>(CollectionContext)
  const { dropPageData } = useContext<any>(DropPageDataContext)
  const [{ isPending: paypalButtonNotAvailable }] = usePayPalScriptReducer()
  const { createOrder, onApprove, onCancel, onError } = usePayPal({ dropPageData })
  const selectedEdition = getLocalStorage(storage.SELECTED_EDITION)

  const payment_id = paymentMethods.find(method => method.id === value)?.payment_id

  const handlePay = () => {
    setIsPaying(true)
    onPaymentSelected(payment_id ?? '')
  }

  const isUniqueCollection = dropPageData?.payment_flow === 'unique_nft_purchase'
  const isUnlimitedEditionPurchase = dropPageData?.payment_flow === 'unlimited_edition_purchase'

  const priceAndTotal = () => {
    if (isUnlimitedEditionPurchase) {
      if (selectedEdition?.currency === 'EUR') {
        return ` €${Number(selectedEdition?.total)}`
      } else if (selectedEdition?.currency === 'ETH') {
        return `${selectedEdition?.total} ETH`
      } else `${selectedEdition?.total} ${selectedEdition?.currency}`
    }

    if (isUniqueCollection) {
      if (collection?.currency === 'EUR') {
        return ` €${Number(collection?.total)}`
      } else if (collection?.currency === 'ETH') {
        return `${collection?.total} ETH`
      } else `${collection?.total} ${collection?.currency}`
    } else {
      return isTurkish ? ` ${selectedEdition?.price * purchaseQuantity} TL ` : ` €${selectedEdition?.price * purchaseQuantity} `
    }
  }

  // TODO: To remove isTurkish after gunes is translated (or we just not deploy anymore)
  const Total = () => (
    <StyledTotalText>
      {isTurkish ? 'Toplam:' : t('payment_total')} {priceAndTotal()}
    </StyledTotalText>
  )

  return (
    <Box textAlign="center">
      <StyledHeaderText>{isTurkish ? 'Ödeme yöntemleri' : t('payment_methods')}</StyledHeaderText>
      <StyledSubtitle>{t('confirm_unrestricted_passport_description')}</StyledSubtitle>

      <PaymentMethodsList value={value} setValue={setValue} isTurkish={isTurkish} allowedPaymentIds={allowedPaymentIds} />

      <StyledHStack
        sx={{
          marginTop: 10,
          justifyContent: 'space-between',
        }}
      >
        <StyledTotalText>
          {purchaseQuantity}x {selectedEdition?.name}
        </StyledTotalText>
        <Total />
      </StyledHStack>

      <StyledBox width="100%" display="flex" flexDir="column" gap="15px">
        <Checkbox isChecked={termsChecked} onChange={e => setTermsChecked(e.target.checked)} alignItems="flex-start">
          <StyledCheckbox>
            {t('i_approve') + ' '}
            <StyledLink href="https://docs.google.com/document/d/10cUWXTmMIenjulJm7FfxhR-q8dA_6aZH/edit" isExternal>
              {t('special_conditions_of_purchase')}
            </StyledLink>{' '}
            {t('and')}{' '}
            <Box>
              <StyledLink href="https://docs.google.com/document/d/1DqmE5UeM3g6MvjuUj4GhtXa5HbaBdqcq/edit" isExternal>
                {t('premium_pass_conditions')}
              </StyledLink>{' '}
              {t('zu')}
            </Box>
          </StyledCheckbox>
        </Checkbox>

        <Checkbox isChecked={isPrivacyChecked} onChange={e => setPrivacyChecked(e.target.checked)} alignItems="flex-start">
          <StyledCheckbox>{<div dangerouslySetInnerHTML={{ __html: t('i_agree_for_use_of_personal_data') }} />}</StyledCheckbox>
        </Checkbox>
      </StyledBox>

      <PaymentButtonContainer>
        {payment_id === PaymentID.PAYPAL ? (
          paypalButtonNotAvailable ? null : (
            <Box w="200px">
              <PayPalButton
                disabled={!termsChecked || !isPrivacyChecked}
                createOrder={createOrder}
                onApprove={onApprove}
                onCancel={onCancel}
                onError={onError}
              />
            </Box>
          )
        ) : (
          <StyledPayButton onClick={() => !isPaying && handlePay()} isDisabled={isPaying || !termsChecked || !isPrivacyChecked} isLoading={isPaying}>
            {isTurkish ? 'öde' : t('payment_buy_now')}
          </StyledPayButton>
        )}
      </PaymentButtonContainer>
    </Box>
  )
}
