import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { signOut } from 'next-auth/react'
import styled from '@emotion/styled'
import { useTranslation } from 'next-i18next'
import SpotifyIcon from './icons/SpotifyIcon'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { queries } from '@fuel/core'
import useConnectedApps from '../../hooks/useConnectedApps'
import { toast } from 'react-hot-toast'

const StyledModalContent = styled(ModalContent)`
  background-color: #101016;
  color: #e8ecfb;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 260px;
`

const DisconnectButton = styled(Button)`
  background-color: rgba(239, 68, 68, 1);
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 25px;

  &:hover {
    background-color: rgba(239, 68, 68, 0.8);

    &:disabled {
      background-color: rgba(239, 68, 68, 0.8);
    }
  }
`

interface Props {
  isOpen: boolean
  closeModal: any
}

const ConnectedAppsModal = ({ isOpen, closeModal }: Props) => {
  const { t } = useTranslation('auth')
  const [buttonLoading, setButtonLoading] = useState(false)
  const { getUserSpotify, deleteUserSpotify } = useConnectedApps()
  const { data: userConnectedToSpotify, refetch: refetchUserConnectedToSpotify } = useQuery([queries.GET_USER_SPOTIFY], getUserSpotify, {
    refetchOnMount: true,
    enabled: isOpen,
  })

  const { mutate } = useMutation(deleteUserSpotify, {
    onSuccess: () => {
      signOut({ redirect: false })
      setButtonLoading(false)
      refetchUserConnectedToSpotify()
    },
    onError: () => {
      setButtonLoading(false)
      toast.error(t('unable_to_delete_user'))
    },
  })

  const handleDisconnect = async () => {
    setButtonLoading(true)
    await mutate()
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size={['full', 'lg']}>
      <ModalOverlay />
      <StyledModalContent>
        <ModalCloseButton color="white" />
        <ModalBody width="100%">
          <motion.div initial={{ y: 0, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 0, opacity: 0 }} transition={{ delay: 0.2 }}>
            <Box display="flex" flexDir="column" justifyContent="center" gap="30px">
              <Box width="100%" display="flex" alignItems="flex-start" flexDir="column" mt="50px">
                <Text fontSize="20px" fontWeight={700} mb="10px">
                  {t('connected_accounts')}
                </Text>
                <Text fontSize="14px">{t('manage_accounts_connected_to_profile')}</Text>
              </Box>
              <Box>
                {userConnectedToSpotify ? (
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" gap="10px" alignItems="center">
                      <SpotifyIcon fontSize="18px" />
                      <Text>Spotify</Text>
                    </Box>
                    <DisconnectButton isLoading={buttonLoading} size="sm" onClick={handleDisconnect}>
                      {t('disconnect')}
                    </DisconnectButton>
                  </Box>
                ) : (
                  <Text fontStyle="italic">{t('no_connected_accounts')}</Text>
                )}
              </Box>
            </Box>
          </motion.div>
        </ModalBody>
      </StyledModalContent>
    </Modal>
  )
}

export default ConnectedAppsModal
