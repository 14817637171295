import { PaymentID, PaymentMethod } from '@fuel/payment/model/PaymentMethod'
import { FiCreditCard } from 'react-icons/fi'
import { GiTwoCoins } from 'react-icons/gi'
import { PayPalIcon } from '@fuel/ui/icons/PayPalIcon'

// This is only a translation
export const turkishPaymentMethods: PaymentMethod[] = [
  {
    id: '1',
    icon: FiCreditCard,
    title: 'Kredi Kartı',
    subtitle: 'Kolay ödeme.Apple/Google Pay ile uyumlu',
    recommended: true,
    payment_id: PaymentID.STRIPE,
  },
  {
    id: '2',
    icon: GiTwoCoins,
    title: 'Crypto ile öde',
    subtitle: 'Kolay ödeme.Gas fee uygulanır',
    recommended: false,
    payment_id: PaymentID.COINBASE,
  },
  {
    id: '3',
    icon: PayPalIcon,
    title: 'PayPal',
    subtitle: 'PayPal ile kolay ödeme.',
    recommended: false,
    payment_id: PaymentID.PAYPAL,
  },
]

export enum PurchaseStatus {
  NOT_STARTED = 'not_started',
  NFT_AVAILABLE = 'nft_available',
  ENQUEUED = 'enqueued',
  SOLD_OUT = 'sold_out',
}
