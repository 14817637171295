import { theme } from '@chakra-ui/react'

export const Button = {
  baseStyle: {
    cursor: 'pointer',
  },
  variants: {
    primary: (props: any) => ({
      border: '1px solid #C3EC9B',
      background: `${props.theme.colors.primary.main}`,
      color: `${props.theme.colors.button.primary}`,
      borderRadius: 'full',
    }),
  },
}
