export const theme = {
  palette: {
    primary: {
      main: '#D5FE9C',
      dark: '#57A871',
    },
    secondary: {
      main: '#907DFE',
      dark: '#4F5079',
      light: '#BCB1FE',
    },
    alt: {
      main: '#66FFC7',
    },
    button: {
      primary: '#000',
      secondary: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#fff',
      disabled: '#A0ABB0',
      warning: '#D8980B',
      success: '#57A871',
      error: '#F44336',
      dark: '#000',
    },
    background: {
      primary: '#000',
      light: '#FBFBFB',
      stroke: '#DBE3EC',
    },
    common: {
      white: '#FFF',
      black: '#000',
      gray: '#A0ABB0',
      disabled: '#A0ABB060',
      error: '#F44336',
      success: '#D6FE9B',
      info: '#33A2B8',
      warning: '#FBEDCF',
    },
  },
  input: {
    background: '#F5F7F7',
    color: '#000',
    fontSize: {
      base: '14px',
    },
    fontFamily: {
      primary: 'Epilogue',
    },
  },
  breakpoints: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
  spacing: (multiplier = 1) => `${4 * multiplier}px`,
  borderRadius: {
    sm: '4px',
    md: '6px',
    lg: '10px',
    base: '60px',
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  fontFamily: {
    primary: 'Space Mono',
    secondary: 'Epilogue',
    button: 'Space Mono',
    title: 'Epilogue',
  },
  fontSize: {
    xxs: '12px',
    xs: '14px',
    sm: '16px',
    md: '18px',
    lg: '20px',
    base: '24px',
    xl: '32px',
    '2xl': '40px',
    '3xl': '80px',
    button: '14px',
  },
  shadows: {
    base: '4px 0px 17px rgba(83, 127, 215, 0.05);',
    lg: '0px 4px 14px rgba(0, 0, 0, 0.25)',
  },
  typography: {
    h3: {
      'font-size': '21px',
    },
    body: {
      'font-size': '14px',
    },
    body2: {
      'font-size': '13px',
    },
  },
  button: {
    padding: '8px 40px',
    primary: {
      border: `1px solid #C3EC9B`,
    },
    secondary: {
      border: `1px solid transparent`,
    },
  },
}
